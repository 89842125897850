import './style.css';
import { ethers } from 'ethers';
import { ConnectKitButton } from 'connectkit';
import { useAccount } from 'wagmi'
import { useState, useEffect } from 'react';
import Modal from 'react-modal';
import Gift from './components/Gift';
import NFTCube from './components/Cube';
import ParticipationControls from './components/ParticipationControls';
import config from "./config";
import UserNFTs from './components/UserNFTs';
import SubmitNFT from './components/SubmitNFT';
import axios from "axios";

Modal.setAppElement('#root');

function App() {
  const { abi } = require('./SecretSanta.json')
  const { nftabi } = require('./nft_abi.json')

  const provider = new ethers.providers.Web3Provider(window.ethereum);
  const signer = provider.getSigner();
  const secretSantaContract = new ethers.Contract( config.secretSantaAddress , abi , signer )

  const { address, isConnected } = useAccount();
  const [entries,setEntries] = useState(0)
  const [eligible,setEligible] = useState(true);
  const [userNFTs,setUserNFTs] = useState([]);
  const [selectedNFT,setSelectedNFT] = useState(null)
  const [cube1,setCube1] = useState([null,null,null,null,null,null]);
  const [cube2,setCube2] = useState([null,null,null,null,null,null]);
  const [cube3,setCube3] = useState([null,null,null,null,null,null]);
  const [cube4,setCube4] = useState([null,null,null,null,null,null]);

  // modal
  const modalStyles = {
    overlay: {
      background: 'rgba(0, 0, 0, 0.3)'
    },
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      minWidth: '480px',
      width: '50%',
      color: '#222'
    },
  };
  let subtitle;
  const [modalIsOpen, setIsOpen] = useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = '#f00';
  }

  function closeModal() {
    setIsOpen(false);
  }

  const selectNFT = (nft) => {
    setSelectedNFT(nft)
  }

  const fetchUserNFTs = async (page=1) => {
      axios
      .get(`${config.proxyApi}?address=${address}&page=${page}`)
      .then(response => {
        const data = response?.data?.data
        if(data.length>0){
          let paginatedData = [];

          data.map((nft) => {
            if(nft.verified == 'verified')
            paginatedData.push({
              image: nft.metadata.image,
              name: nft.metadata.name ? nft.metadata.name : nft.collectionName + '#' + nft.tokenId,
              collectionName: nft.collectionName,
              collectionContract: nft.collection,
              tokenId: nft.tokenId
            })
          })
          
          setUserNFTs((previous) => [...previous, ...paginatedData])
          if(data.length == 50){
            fetchUserNFTs(page+1)
          }
          
        }
      }).catch(function(error) {
        console.log(error);
      });
    
    
  }
  const fetchSecretSantaInfo = async () => {
    // call contract and fetch Secret Santa info:
  
    let entriesTotal = ethers.utils.formatUnits( await secretSantaContract.totalEntries(),0);
    let participated = await secretSantaContract.participated(address);

    setEntries(entriesTotal)
    setEligible(!participated)

    let latestEnties = await secretSantaContract.getLatestEntries(20);
    let messages = [];
    let nfts = [];
    latestEnties.map((entry) => {
      messages.push(entry.message)
      nfts.push([entry.collection,ethers.utils.formatUnits(entry.tokenId,0)])
    })
    
    const imgs = await getImagesFromNFTContracts(nfts)


  }

  const getImagesFromNFTContracts = async(nfts) => {
    let images = [];
    nfts.map(async(nft,i) => {
      axios
      .get(`${config.proxyApi}/item?address=${nft[0]}&tokenId=${nft[1]}`)
      .then(response => {
        const data = response?.data?.data
        if(data){
          images.push(data.metadata.image.replace("ipfs://", "https://ipfs.io/ipfs/"))
          // on last fetch, split images in cubes and set state
          if(i == nfts.length -1){
            let cubeFacets = [ [], [], [], [] ]

            images.map((image,i) => {
              cubeFacets[i%4].push(image)
            })
            setCube1(addNullPadding(cubeFacets[0]))
            setCube2(addNullPadding(cubeFacets[1]))
            setCube3(addNullPadding(cubeFacets[2]))
            setCube4(addNullPadding(cubeFacets[3]))
          }
          
        }
      }).catch(function(error) {
        console.error('could not fetch NFT metadata for token #'+nft[1]+' of collection <'+nft[0]+'>')
        console.log(error);
      });

    })

  }

  const addNullPadding = (cube) => {
    while(cube.length<6){
      cube.push(null)
    }
    return cube;
  }

  const depositNFT = async (nft) => {
    // check if secretSantaContract is approved for target NFT collection
    const nftContract = new ethers.Contract( nft.collectionContract, nftabi , signer )
    await nftContract.approve(config.secretSantaAddress,nft.tokenId).then( async (approval) => {
      const deposit = await secretSantaContract.deposit(nft.collectionContract,nft.tokenId,'')
    })
    
  }


  useEffect(() => {

    (async () => {

        setUserNFTs([])
        fetchUserNFTs()
        fetchSecretSantaInfo()
    })()
    
    }, [isConnected]);
  
  return (
    <div className="App">

      <div className="overlay">
        <div className="menu">
          <div className="entries">
            ENTRIES: {entries}
          </div>
          <div className="connect-button">
            <ConnectKitButton />
          </div>
        </div>
        <div className="heading">
          <h1>(avaXmas)</h1>
          <h3>secret santa</h3>
        </div>
        <div className="panel">
          <h2>Spread the joy! gift an NFT and get one back</h2>
          <ul>
            <li>gift an NFT to the pool (until 31/12 23:59 GMT)</li>
            <li>claim a random NFT back after New Years Eve</li>
          </ul>
          
          <p></p>
          <br></br>
          <hr></hr>
          <br></br>
          <ParticipationControls 
            isConnected={isConnected}
            eligible={eligible}
            openModal={openModal}
          ></ParticipationControls>
        </div>
        <div className="footer">
          <div className="credits">
            code by <a href="https://twitter.com/gfk_acid" target="_blank">0xAcid</a> | <a href="https://snowtrace.io/address/0xF990E1c5B989d1cA1F7Cda22362778A8615f7f20" target="_blank"><img width='18' src="/snowtrace.svg"></img>contract</a> by <a href="https://twitter.com/moneyduplos" target="_blank">jumbo</a> | giftbox designs by <a href="https://twitter.com/SOPESGAL" target="_blank">LY</a>
          </div>
          <div className="tweet">
            <a href="https://twitter.com/intent/tweet?url=https%3A%2F%2Favaxmas.xyz%2F&text=I%27m%20playing%20Secret%20Santa%20on%20AVAX%21%20Join%20%26%20spread%20the%20joy%20%3A%29&hashtags=SecretSanta%2CAVAXMAS%2CNFT" target="_blank">
              <span><small>Tweet about this!</small></span>
              <img width='24' src="/twitter_logo_blue.png" />
            </a>
          </div>
        </div>
      </div>

      <div className="scene">
        <div className="outer">
          <div className="outer_scene">
            <div className="outer_floor"></div>
            <div className="gifts">
              <Gift/>
              <Gift/>
              <Gift/>
              <Gift/>
            </div>
          </div>
        </div>

        <div className="portal">
          <div className="portal_scene">
            <div className="portal_floor"></div>
            
            <NFTCube imgs={cube1}/>
            <NFTCube imgs={cube2}/>
            <NFTCube imgs={cube3}/>
            <NFTCube imgs={cube4}/>
          </div>
        </div>

        <div className="outer">
          <div className="outer_scene">
            <div className="gifts">
              <i></i>
              <i></i>
              <i></i>
              <i></i>
              <Gift/>
              <Gift/>
              <Gift/>
              <Gift/>
            </div>
          </div>
        </div>
      </div>

      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={modalStyles}
        contentLabel="Example Modal"
      >
        <h2 ref={(_subtitle) => (subtitle = _subtitle)}>Select NFT to gift</h2>
        <button className="modal-close-btn" onClick={closeModal}>x</button>
        <br></br>
        <UserNFTs 
          nfts={userNFTs}
          address={address}
          selectNFT={selectNFT}
        />
        <SubmitNFT 
          selectedNFT={selectedNFT}
          depositNFT={depositNFT}
          />
      </Modal>

    </div>
  );
}

export default App;
