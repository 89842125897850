function NFTCube(props) {
    const images = props.imgs;
    const defaultImage = "url('/avax_bg.png')";

    return (
        <div className="cube">
            <div className="cube_shadow"></div>
            <div className="cube_body">
            {images.map((image,i) => (
                <div key={i} style={{backgroundImage: image !== null ? 'url('+image+')' : defaultImage, backgroundSize: 'cover' }}></div>
            ))}
            </div>
        </div>
    )
}

export default NFTCube;