import { useState } from "react";

function SubmitNFT(props) {
    const {selectedNFT,depositNFT} = props
    const [busy,setBusy] = useState(false)
    
    return (
        <div id="action-bar">
            {selectedNFT ? (
                <div className="wrapper">
                    <span>
                        Selected: <strong>{selectedNFT.name}</strong> 
                    </span>
                    {!busy ? (
                        <span className="deposit-button" onClick={() => depositNFT(selectedNFT)}>
                            Send NFT
                        </span>
                    ) : (
                        <span className="busy-button"> - </span>
                    )}
            
                </div>
            ) : (
                <span> Select an NFT </span>            
            )}
        </div>
        
    )
}

export default SubmitNFT;