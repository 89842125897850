function Gift() {
    return (
        <div className="gift">
            <div className="gift_shadow"></div>
            <div className="gift_body">
                <div className="gift_side"></div>
                <div className="gift_side"></div>
                <div className="gift_side"></div>
                <div className="gift_side"></div>
                <div className="gift_top"></div>
                <div className="gift_bottom"></div>
            </div>
        </div>
    )
}

export default Gift;