import Countdown from "react-countdown";
import config from "../config";

function ParticipationControls(props) {
    const {isConnected, eligible, openModal} = props;
    return (
        <div className="controls">
            {isConnected == true ? (
                <div>
                    {eligible == true ? (
                        <button onClick={openModal}>Wrap your NFT gift</button>
                    ):(
                        <div>
                            {Date.now() > config.end ? (
                                <div> [CLAIM] </div>
                            ):(
                                <div style={{textAlign: 'center'}}>
                                    <p>Your gift is safe in Santa's bag! Claim yours in:</p>
                                    
                                    <Countdown date={config.end} />
                                </div>
                            )}
                            
                        </div>
                    )}
                </div>
            ):(
                <p>please connect your wallet</p>
            )}
        </div>
    )
}

export default ParticipationControls;